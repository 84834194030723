import React, { FunctionComponent } from "react"
import "../components/layout.css"
import styled from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh !important;
  overflow: scroll !important;
`;

const Container = styled.div`
  width: 50%;
  background: white;
  min-height: 100vh !important;
  overflow: auto !important;
  padding: 60px;
  margin: 60px 0;

  @media screen and (max-width: 1020px) { 
    width: 85%;
    padding: 40px;
    margin: 60px 0;
  }
  @media screen and (max-width: 750px) { 
    width: 90%;
    padding: 30px;
    margin: 30px 0;
  }

`;

const PressImage = styled.img.attrs(() =>({
  src: pressImage
}))`
`

const Presseskriv: FunctionComponent = () => {

  return (
    <div className="layout">
      <OuterContainer>
        <Container>

          <h1 id="Presseskriv">Et jävla liv vi lever </h1>

          <p> Det är ett jävla liv vi lever <br/>
            Det är ett jävla liv vi lever <br/>
            Det är ett jävla liv vi lever <br/>
            Det är ett jävla liv vi lever <br/>
          </p>


<p>Mail från kunden, åldrande vänskap, barnlöshet, träning och öl till maten <br/>
Det samlar sig, i ett säkerhetsbälte, av stress över bröstet, och håller oss fast</p>

<p>Att hålla bäbis känns sant (ett jävla liv vi lever) <br />
Att trösta flickvän känns sant (ett jävla liv vi lever) <br />
Att se på schack känns sant (ett jävla liv vi lever) <br />
Ett jävla liv vi lever</p>

<p>Vi sitter här, över allting, och tittar ned på dagar som går<br/>
Väntar på att lampan ska slockna, unfasten our seatbelts<br/>
Ställa oss upp, under läckande luft-<br/>
Konditioneringar och vänta på vår tur att gå av<br/>
Men man vill inte känna så, tvärtom man vill vara glad<br/>
Så man gör det man kan, med de stunder man har<br/>
Jag vill bara väljas ut av hundar och barn<br/>
</p>
<p>
Att hålla en bäbis känns sant (Ett jävla liv vi lever)<br/>
Att trösta en flickvän känns sant (Ett jävla liv vi lever)<br/>
Att se på schack känns sant (Ett jävla liv vi lever)<br/>
Ett jävla liv vi lever<br/>
</p>

<p>Lev varje dag som om det var din sista<br/>
Hejsan är det du - som er strømsjefen i huset mister<br/>
Tolv missade samtal, tretton snälla små kolleger<br/>
Fjorton ängsliga personer som vill veta om jag lever<br/>
Lätt att fatta svår att ringa är det sådan du vill vara<br/>
Smörja ögonen med lök och äta maybelline mascara<br/>
Stora känslor är förbjudna för de skrämmer bort de små<br/>
Men luften som vi andas kan'te skilja på de två<br/>
</p>

<p>Att hålla bäbis känns sant (ett jävla liv vi lever)<br/>
Att trösta flickvän känns sant (ett jävla liv vi lever)<br/>
Att se på schack känns sant (ett jävla liv vi lever)<br/>
Ett jävla liv vi lever<br/>
</p>
          
        </Container>
      </OuterContainer>
      </div>
    ) 
  }

export default Presseskriv;